<template>
  <section class="app-kanban-wrapper">
    <todo :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive" @re-fetch-task="reFetchTask" @filter-task-by-phone="listFilterByPhone" @filter-task-by-created-by="listFilterByCreatedBy" :boardName="boardName" :phoneList="phoneList" :createdByList="createdByList"></todo>
  <div class="kanban-wrapper ps">
    <div class="kanban-container">
      <div v-for="(listItem, index) in lists" :key="index" :data-id="'board-in-' + listItem.id" data-order="1"
        class="kanban-board" style="width: 200px; margin-left: 15px; margin-right: 15px;">
        <header class="kanban-board-header">
          <div class="kanban-title-board" contenteditable="false">{{ listItem.name }}</div> 
          <div class="badge rounded-pill badge-light-secondary"> {{listItem.tickets.length}} </div>
          <!-- <button class="kanban-title-button btn btn-flat-secondary btn-sm ms-50 waves-effect"
            v-if="listItem.name === 'Шинэ'" @click="addTaskClick(task)">+ Шинэ ажил нэмэх</button> -->
          <div class="dropdown">
            <feather-icon class="dropdown-toggle cursor-pointer font-medium-3 me-0" icon="MoreVerticalIcon" size="14" />
            <!-- <div class="dropdown-menu dropdown-menu-end" aria-labelledby="board-dropdown">
              <a class="dropdown-item delete-board" href="#"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash font-medium-1 align-middle">
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                </svg>
                <span class="align-middle ms-25">Delete</span>
              </a>
                <a class="dropdown-item" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-edit font-medium-1 align-middle">
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                <span class="align-middle ms-25">Rename</span>
              </a>
              <a class="dropdown-item" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-archive font-medium-1 align-middle">
                    <polyline points="21 8 21 21 3 21 3 8"></polyline>
                    <rect x="1" y="3" width="22" height="5"></rect>
                    <line x1="10" y1="12" x2="14" y2="12"></line>
                  </svg>
                <span class="align-middle ms-25">Archive</span>
              </a>
            </div> -->
          </div>
        </header>
        <vue-perfect-scrollbar ref="refKanbanDrag" :settings="perfectScrollbarSettings" class="scroll-area">
          <!-- @end="changeStatus"  shalgah -->
          <draggable :list="listItem.tickets" tag="ul" group="people" @change="changeStatus($event, listItem)"
            class="list-group list-group-flush cursor-move kanban-drag">
            <div class="kanban-item" v-for="(ticketItem, ticketIndex) in listItem.tickets" :key="ticketIndex"
              v-b-modal.modal-task-detail @click="handleTaskClick(ticketItem)">
              <div class="d-flex justify-content-between flex-wrap align-items-center mb-1">
                <!-- <div class="item-badges" style="">
                  <div > 
                    {{}} 
                  </div>
                </div> -->
                <small :class="`text-${resolvePriorityVariant(ticketItem.priority)} text-uppercase fw-medium`" style="width: 150px"><b>{{ ticketItem.ticket_categories_names }}</b></small>
                <div class="item-badges __priority">
                  <div :class="`__priority_badge badge rounded-pill badge-${resolvePriorityVariant(ticketItem.priority)}`" v-b-tooltip.hover.v-dark :title="ticketItem.priority" v-if="ticketItem.priority!==''">
                    <feather-icon class="mr-25" icon="FlagIcon" size="18" />
                  </div>
                </div>
                <!-- <div class="dropdown item-dropdown px-1">
                  <feather-icon class="dropdown-toggle cursor-pointer me-0 font-medium-1" icon="MoreVerticalIcon"
                    size="14" />
                  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="item-dropdown"><a class="dropdown-item"
                      href="#">Copy task link</a><a class="dropdown-item" href="#">Duplicate task</a><a
                      class="dropdown-item delete-task" href="#">Delete</a></div>
                </div> -->
              </div>
              <span class="kanban-text">
                <div class="badge badge-light-primary">{{ ticketItem.phone }}</div> 
                {{ ticketItem.description }}
              </span>
              <div class="d-flex justify-content-between align-items-center flex-wrap mt-1">
                <div class="__calendar_created_due">
                  <span class="align-middle me-50">
                    <span class="attachments align-middle">{{ ticketItem.created_at }}-{{ ticketItem.due_date }}</span>
                  </span>
                  <span class="align-middle">
                    <!-- <span class="attachments align-middle">{{ ticketItem.created_at }} - {{ ticketItem.due_date }}</span> -->
                  </span>
                  <br/>
                  <!-- <span class="align-middle me-50">
                    <feather-icon class="font-medium-1 align-middle me-25" icon="PaperclipIcon" size="18" />
                    <span class="attachments align-middle">2</span>
                  </span>
                  <span class="align-middle">
                    <feather-icon class="font-medium-1 align-middle me-25" icon="MessageSquareIcon" size="18" />
                    <span>6</span>
                  </span> -->
                </div>
                <ul class="avatar-group mb-0 __avatar_group">
                  <b-avatar v-if="ticketItem.created_by" size="24" :src="ticketItem.created_by"
                    :text="avatarText(ticketItem.created_by)" v-b-tooltip.hover.v-primary :title="ticketItem.created_by" class="avatar kanban-item-avatar  pull-up  me-0" variant="light-primary"/>
                  <b-avatar v-if="ticketItem.assignee" size="24" :src="ticketItem.assignee"
                    :text="avatarText(ticketItem.assignee)" v-b-tooltip.hover.v-warning :title="ticketItem.assignee" class="avatar kanban-item-avatar  pull-up  me-0" variant="light-warning"/>
                  <b-avatar v-else size="24" variant="light-secondary">
                    <feather-icon icon="UserIcon" size="16" />
                  </b-avatar>
                </ul>
              </div>
            </div>
          </draggable>
        </vue-perfect-scrollbar>
      </div>
    </div>

    <!-- Task Modal -->
    <todo-task-edit v-model="isTaskHandlerModalActive" :task="task" :lists="lists" :clear-task-data="clearTaskData" @update-task="updateTask" @re-fetch-task="reFetchTask"/>

    <!-- Task Handler -->
    <todo-task-handler-sidebar v-model="isTaskHandlerSidebarActive" :task="task" :clear-task-data="clearTaskData"
      @remove-task="removeTask" @add-task="addTask" @update-task="updateTask"/>
  </div>
  </section>
</template>

<script>
import store from '@/store'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BListGroupItem, BAvatar, BRow, BCol, BCardText, BButton, VBModal, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import TodoTaskEdit from './TodoTaskEdit.vue'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Todo from './Todo.vue'

export default {
  components: {
    BCardCode,
    BAvatar,
    BListGroupItem,
    BCardText,
    BRow,
    BCol,
    Prism,
    draggable,
    TodoTaskHandlerSidebar,
    VuePerfectScrollbar,
    BButton,
    TodoTaskEdit,
    Todo
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup(props, context) {
    console.log('propContext', props, context)
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasksByBoard()
    })

    const lists = ref([])

    const allLists = ref([])

    const isTaskHandlerSidebarActive = ref(false)

    const isTaskHandlerModalActive = ref(false)

    const phoneList = ref([])

    const createdByList = ref([])
    
    const requestParam = ref(null)

    const boardName = ref('')

    const blankTask = {
      id: null,
      title: '',
      // uurt ni bsan
      dueDate: new Date(),
      description: '',
      assignee: null,
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
      // nemsen
      code: '',
      phone: '',
      status: 'Шинэ',
      categoryIds: [],
      productIds: [],
      tagIds: [],
      attachmentNames: [],
      priority: '',
      workspace: 'Нөмөр Кредит ББСБ',
      space: 'Нөмөр асуудал',
      folder: 'Нээлттэй хавтас',
      board: 'Нээлттэй самбар',
      list: 'Шинэ',
      due_date: new Date(),
      is_complete: false,
      is_draft: false,
      is_sent: false,
      created_at: new Date(),
      created_by: JSON.parse(localStorage.getItem('userData')).username,
      customer_id: '',
      merchant_id: '',
      firstname: '',
      lastname: '',
      organization: 'Нөмөр Кредит ББСБ',
      team: '',
      is_deleted: false,
      // deleted_at: null,
      // deleted_by: '',
      call_type: 'inbound',
      pay_score: 0,
      promised_at: null,
      started_at: null,
      finished_at: null,
      returned_at: null,
      completed_at: null,
      comments: [
      ],
      comment: '',
      customer: {
        customer_id: 0,
        customer_per_id: 0,
        firstname: '',
        lastname: '',
        register_number: '',
        ticket_id: 0,
        phoen: ''
      }
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const addTask = val => {
      console.log('val', val)
      store.dispatch('app-todo/addTask', val)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasksByBoard(null)
        })
    }
    const removeTask = () => {
      store.dispatch('app-todo/removeTask', { id: task.value.id })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasksByBoard(null)
        })
    }
    const updateTask = taskData => {
      store.dispatch('app-todo/updateTask', taskData)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasksByBoard(null)
        })
    }

    const reFetchTask = (param) => {
      fetchTasksByBoard(param)
    }

    const changeStatus = (event, param) => {
      if (event.hasOwnProperty("added")) {
        store.dispatch('app-todo/changeStatusTask', { ticketId: event.added.element.id, newList: param })
          .then(() => {
            // eslint-disable-next-line no-use-before-define
            fetchTasksByBoard(null)
          })
      }


    }

    const dropEnd = (event, param) => {
      console.log("changeStatus", event, param, lists)
    }


    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const taskTags = [
      { title: 'Team', color: 'primary', route: { name: 'todo-tag', params: { tag: 'team' } } },
      { title: 'Low', color: 'success', route: { name: 'todo-tag', params: { tag: 'low' } } },
      { title: 'Medium', color: 'warning', route: { name: 'todo-tag', params: { tag: 'medium' } } },
      { title: 'High', color: 'danger', route: { name: 'todo-tag', params: { tag: 'high' } } },
      { title: 'Update', color: 'info', route: { name: 'todo-tag', params: { tag: 'update' } } },
    ]

    const resolvePriorityVariant = priority => {
      if (priority === 'team') return 'primary'
      if (priority === 'Энгийн') return 'success'
      if (priority === 'Яаралтай') return 'warning'
      if (priority === 'Маш яаралтай') return 'danger'
      if (priority === 'update') return 'info'
      return 'info'
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    }

    const fetchTasksByBoard = (param) => {
      createdByList.value = []
      phoneList.value = []
      if (route.value.name === "todo-board" || route.value.name === "home") {
        requestParam.value = {
          board:'Нээлттэй самбар'
        }
        boardName.value = ''
      } else {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        boardName.value = (date.toISOString().split('T')[0])
        if (!param) {
          requestParam.value = {
            board: boardName.value
          }
        } else {
          requestParam.value = {
            board: param
          }
        }
        
      }
      store.dispatch('app-todo/fetchTasksByBoard', requestParam.value)
        .then(response => {
          lists.value = [...response.data.response]
          let tempLists = []
          for (let pIndex in response.data.response) {
            let tempTickets = []
            for (let cIndex in response.data.response[pIndex].tickets) {
              if (!createdByList.value.find(x => x.name === response.data.response[pIndex].tickets[cIndex].created_by )) {
                createdByList.value.push(
                  {
                    name: response.data.response[pIndex].tickets[cIndex].created_by
                  }
                )
              }
              if (!phoneList.value.find(pl => pl.name === response.data.response[pIndex].tickets[cIndex].phone )) {
                phoneList.value.push(
                  {
                    name: response.data.response[pIndex].tickets[cIndex].phone
                  }
                )
              }
              tempTickets.push(Object.assign({}, response.data.response[pIndex].tickets[cIndex]))
            }
            let tempList = Object.assign({}, response.data.response[pIndex])
            tempList.tickets = tempTickets
            tempLists.push(tempList)
          }
          allLists.value = tempLists
          console.log("allLists0", allLists, lists)
        })
    }

    fetchTasksByBoard(null)

    const handleTaskClick = taskData => {
      store.dispatch('app-todo/fetchTask', taskData)
        .then(response => {
          task.value = response.data.response
          isTaskHandlerModalActive.value = true
        })
    }

    const addTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted
      updateTask(taskData)
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const listFilterByPhone = taskData => {
      
      console.log("allLists", allLists, lists)

      for (let pIndex in lists.value) {
        if (taskData) {
          lists.value[pIndex].tickets = allLists.value[pIndex].tickets.filter(t => t.phone === taskData)
          console.log("allLists1", allLists, lists)
        } else {
          lists.value[pIndex].tickets = allLists.value[pIndex].tickets
          console.log("allLists2", allLists, lists)
        }
      }
    }

    const listFilterByCreatedBy = taskData => {
      for (let pIndex in lists.value) {
        if (taskData) {
          lists.value[pIndex].tickets = allLists.value[pIndex].tickets.filter(t => t.created_by === taskData)
          console.log("allLists1", allLists, lists)
        } else {
          lists.value[pIndex].tickets = allLists.value[pIndex].tickets
          console.log("allLists2", allLists, lists)
        }
      }
    }

    // const notify = () => {
    //   notification.show('Hello World', {
    //     body: 'This is an example!'
    //   }, {})
    // }

    // notify()

    return {
      task,
      lists,
      allLists,
      removeTask,
      addTask,
      updateTask,
      reFetchTask,
      clearTaskData,
      changeStatus,
      dropEnd,
      taskTags,
      perfectScrollbarSettings,
      // UI
      resolvePriorityVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,
      isTaskHandlerModalActive,

      // Click Handler
      handleTaskClick,
      addTaskClick,
      listFilterByPhone,
      listFilterByCreatedBy,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      requestParam,
      boardName,
      phoneList,
      createdByList
    }
  }
}
</script>
<style scoped>
.kanban-board {
  position: relative;
  float: left;
  background: #e2e4e6;
  transition: all .3s cubic-bezier(.23, 1, .32, 1);
}

.kanban-container * {
  box-sizing: border-box;
}

.kanban-application .kanban-wrapper {
  position: relative;
  width: 100%
}

.kanban-application .kanban-wrapper .kanban-container {
  /* display:flex; */
  padding-bottom: 1.5rem;
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board {
  background: transparent;
  height: 100%;
  /* height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 5rem ) ) !important; */
  width: auto !important
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board:focus {
  outline: 0
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-board-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-board-header .kanban-title-board {
  border-radius: .428rem;
  color: #5e5873;
  font-size: 1.1rem;
  font-weight: 500;
  max-width: 13rem;
  overflow: hidden;
  padding: .5rem;
  white-space: nowrap;
  width: 100%
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-board-header .kanban-title-board:focus,
.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-board-header .kanban-title-board:hover {
  background-color: #fff
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-board-header .kanban-title-board:focus {
  outline: 0
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-board-header .dropdown .dropdown-toggle:after {
  display: none
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-drag {
  min-height: 1rem;
  min-width: 16.55rem;
  padding: 0;
  height: 100%;
  /* position: relative;
  height: calc(100% - 3.56rem); */
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-title-button {
  bottom: 0;
  left: -8px;
  margin: -1rem 0;
  position: absolute
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-title-button:focus {
  box-shadow: none
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item {
  /* background: #fff; */
  border-radius: .428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, .1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 1rem 1.2rem;
  position: relative;
  transition: all .3s cubic-bezier(.23, 1, .32, 1), background 0s, color 0s, border 0s;
  width: 16.55rem
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item i,
.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item svg {
  /* stroke: #5e5873 */
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item .kanban-text {
  font-weight: 500;
  font-size: 85%;
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item .item-dropdown {
  cursor: pointer;
  display: none;
  position: absolute;
  right: 0
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item .item-dropdown .dropdown-toggle:after {
  display: none
}

.kanban-application .kanban-wrapper .kanban-container .kanban-board .kanban-item:hover .item-dropdown {
  display: block
}

.kanban-application .add-new-board {
  float: left;
  margin-top: 7px;
  padding: 0 15px
}

.kanban-application .add-new-board label {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0
}

.kanban-application .update-item-sidebar {
  text-align: left
}

.kanban-application .update-item-sidebar .avatar-add-member {
  background-color: rgba(108, 117, 125, .12)
}

.kanban-application .update-item-sidebar .avatar-add-member i,
.kanban-application .update-item-sidebar .avatar-add-member svg {
  stroke: #5e5873
}

.kanban-application .update-item-sidebar .comment-editor .ql-editor {
  min-height: 5.5rem
}

.kanban-application .update-item-sidebar .comment-toolbar.ql-toolbar {
  border-top: 0;
  text-align: right;
  width: 100%
}

.kanban-item.gu-mirror .item-dropdown .dropdown-toggle:after {
  display: none
}

.kanban-container {
  position: relative;
  box-sizing: border-box;
  width: auto
}

.kanban-container * {
  box-sizing: border-box
}

.kanban-container:after {
  clear: both;
  display: block;
  content: ""
}

.kanban-board {
  position: relative;
  float: left;
  background: #e2e4e6;
  transition: all .3s cubic-bezier(.23, 1, .32, 1)
}

.kanban-board.disabled-board {
  opacity: .3
}

.kanban-board.is-moving.gu-mirror {
  transform: rotate(3deg)
}

.kanban-board.is-moving.gu-mirror .kanban-drag {
  overflow: hidden;
  padding-right: 50px
}

.kanban-board header {
  font-size: 16px;
  padding: 15px
}

.kanban-board header .kanban-title-board {
  font-weight: 700;
  margin: 0;
  padding: 0;
  display: inline
}

.kanban-board header .kanban-title-button {
  float: right
}

.kanban-board .kanban-drag {
  min-height: 200px;
  padding: 20px
}

.kanban-board:after {
  clear: both;
  display: block;
  content: ""
}

.kanban-item {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  transition: all .3s cubic-bezier(.23, 1, .32, 1)
}

.kanban-item:hover {
  cursor: move
}

.kanban-item:last-child {
  margin: 0
}

.kanban-item.is-moving.gu-mirror {
  transform: rotate(3deg);
  height: auto !important
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important
}

.gu-hide {
  display: none !important
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important
}

.gu-transit {
  opacity: .2 !important;
  transform: rotate(0) !important
}

.drag_handler {
  background: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: relative;
  float: left;
  top: -3px;
  margin-right: 4px
}

.drag_handler:hover {
  cursor: move
}

.drag_handler_icon {
  position: relative;
  display: block;
  background: #000;
  width: 24px;
  height: 2px;
  top: 12px;
  transition: .5s ease-in-out
}

.drag_handler_icon:after,
.drag_handler_icon:before {
  background: #000;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: .5s ease-in-out
}

.drag_handler_icon:before {
  top: 6px
}

.drag_handler_icon:after {
  bottom: 6px
}

.__calendar_created_due {
  font-size: 10px;
}

.__avatar_group {
  padding-left: 0 !important;
}

@media(max-width: 1770px) {
  .kanban-wrapper {
    overflow-x: scroll !important;

  }
}

.scroll-area {
    height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 5rem  + 66px) );
}

.__priority_badge_icon {
  color: #fff;
}
</style>
